import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import qs from 'qs'
import router from '@/router'
import $ from "jquery";
//  登录成功处理

// 购物车数量变化
export function getCartInfo() {
    const guid = localStorage.getItem('guid')
        const data = qs.stringify({
            cartGUID: guid
        })
        axios.post('/M/Server/GetMyCartInfo', data)
            .then((res) => {
                if (res.data.success) {
                    $(".floatCartTotalQty").text(res.data.id);
                } else {
                    Toast({
                        type: "error",
                        title: res.data.msg,
                    });
                }
            })
            .catch((err) => {
                console.log('err');
            });
}