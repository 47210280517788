
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import qs from 'qs'
import Modal from "@/components/UI/Modal";
import { getCartInfo } from "@/api/useChangeShopCar";
const useShopCar = () => {

    //  后台存储
    function storgeBackstage(id: string, tid: number, qty: number, lotid: number, tcid: number) {
        const guid = localStorage.getItem('guid')
        const data = qs.stringify({
            pid: id,
            tid,
            qty,
            lotid,
            tcid,
            cartGUID: guid
        })
        axios.post('/M/Server/AddToCart', data)
            .then((res) => {
                if (res.data.success) {
                    if(res.data.shopCartGuid) {
                        localStorage.setItem('guid', res.data.shopCartGuid)
                    }
                    if(!guid || guid == 'null'){
                        localStorage.setItem('guid', res.data.shopCartGuid)
                    }
                    getCartInfo()

                    Toast({
                        type: "success",
                        title: res.data.msg,
                    });
                } else {
                    Toast({
                        type: "error",
                        title: res.data.msg,
                    });
                }
            })
            .catch((err) => {
                console.log('err');
            });
    }

    function addShopCar(id: string, tid: number, qty: number, lotid: number, tcid: number, stock: number, nclassId: number) {
        storgeBackstage(id, tid, qty,lotid,tcid);
    }


    function updatecommodity(id: string, qty: number) {
        const guid = localStorage.getItem('guid')
        const data = qs.stringify({
            pid: id,
            qty: qty,
            cartGUID: guid
        })
        axios.post('/M/Server/UpdateCartItem', data).then((res) => {
            if (res.data.success) {
                // Toast({
                //     type: "success",
                //     title: res.data.msg,
                // });
                getCartInfo()
            } else {
                Toast({
                    type: "error",
                    title: res.data.msg,
                });
            }
        })
            .catch((err) => {
                console.log('err');
            });


    }
    function addStorge(id: string, qty: number) {

        updatecommodity(id, qty)

    }
    function subStorge(id: string, qty: number) {

        updatecommodity(id, qty)

    }


    return {
        addShopCar,
        addStorge,
        subStorge
    }

}

export default useShopCar
