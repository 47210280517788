import { reactive } from 'vue'

//标准物质
export interface IproductList {
    id: number;
    classID: string;
    pic: string;
    enname: string;
    specification: string;
    catno: string;
    catnoname: string;
    concentration: string;
}

//菌种 -- 细胞
export interface IstrainOrCellsList {
    id: number;
    pic: string;
    nclassid: string;
    strainDesignations: string;
    bnccid: string;
    speciesGenus: string;
}

//标准
export interface IsstandardList {
    id: number;
    cnName: string;
    standardType: string;
    classPicUrl: string;
    issueDept: string;
    chinaStdType: string;
    statusName: string;
    publishDate: string;
}

export function productMap(data: IproductList): object{
    let result: object = reactive({})
    result = {
        ...data,
        id: data.id,
        classId: data.classID,
        img: data.pic,
        title: data.enname,
        // info: data.specification ? data.catno + "," + data.specification : data.catno,
        // brand: "标准值：" + data.concentration
        catnoname: data.catno.replace(/<\/?.+?>/g,"").replace(/ /g,""), //此处用于搜索去除标签使用
        catno: data.catno,  // 编号
        brand: data.concentration,  // 标准值
        specification: data.specification  // 规格
    }
    return result
}

export function strainOrCellsMap(data: IstrainOrCellsList): object{
    let result: object = reactive({})
    const speciesGenus = (data as any).anclassId==1?'英文：': '拉丁文：'
    result = {
        ...data,
        id: data.id,
        classId: data.nclassid,
        // img: data.pic,
        // img: (data as any).productPicLink,
        title: data.strainDesignations,
        info: data.bnccid + '<span class="wy-brand" v-if="'+(data as any).inunit+'">BNCC</span>',
        brand: speciesGenus + data.speciesGenus,
        bnccPrice: true
    }
    return result
}

//标准
export function standardMap(data: IsstandardList): object{
    let result: object = reactive({})
    const publishdate: string = data.publishDate ? data.publishDate.substring(0, 10) : "暂无信息";
    result = {
        ...data,
        id: data.id,
        classId: data.standardType,
        img: data.classPicUrl,
        title: data.cnName,
        info: data.issueDept ? data.chinaStdType + "," + data.issueDept : data.chinaStdType,
        brand: "标准状态：" + data.statusName + ' ' + publishdate
    }
    return result
}

