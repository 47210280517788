
import {defineComponent, ref, isReactive, reactive, inject, watch} from "vue";
import api from "@/api/axios";
import Toast from "@/components/UI/Toast/";
import useShopCar from "@/hooks/useShopCar";
import {useRouter} from "vue-router";

export default defineComponent({
  props: {
    list: Object,
    name: String,
    tid: {
      type: String,
      default: "",
    },
    anclassId: {
      type: String,
      default: "",
    },
    typeFlag: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const userInfo = inject("userInfo") as any;
    const biaowuPriceOpen = ref()
    const router = useRouter();
    const isLogin = ref();
    const token = localStorage.getItem('token') || '';
    if(!token){
      isLogin.value = false;
    }else{
      isLogin.value = true;
    }
    watch(
        () => {
          return props.list
        },
        (NewsVue) => {
          biaowuPriceOpen.value = (NewsVue as any).totalResult && (NewsVue as any).totalResult[0] && (NewsVue as any).totalResult[0].str
        }
    )

    //  add shop car
    const {addShopCar} = useShopCar();

    function back() {
      router.go(-1);
    }

    function chat() {
      window.open(
              "https://tb.53kf.com/code/client/670c56f26e85ec66df9d068b5c2cd2531/1",
              "_blank",
              "top=300,left=500,width=800,height=600,menubar=no,toolbar=no,status=no,scrollbars=yes"
      );
      return false;
    }
    return {
      addShopCar,
      userInfo,
      biaowuPriceOpen,
      back,
      isLogin,
      chat
    };
  },
});
